import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AuthRoute, ErrorSegment } from "net-common-ui";
import MainLayout from "./components/MainLayout";
// Views:
import getUserConfirmation from "./components/getUserConfirmation";
import { LicenseRoutes } from "./License";

const App: React.VFC = () => (
    <Router getUserConfirmation={getUserConfirmation} basename={process.env.PUBLIC_URL}>
        <MainLayout>
            <Root />
        </MainLayout>
    </Router>
);

export default App;

const Root: React.VFC = () => {
    return (
        <Switch>
            <AuthRoute path="/" exact>
                <Redirect to="/license" />
            </AuthRoute>
            <AuthRoute path="/license" component={LicenseRoutes} />
            <Route>
                <ErrorSegment.NotFound />
            </Route>
        </Switch>
    );
};
