import { Switch, Route, useRouteMatch } from "react-router-dom";
import { AuthRoute, ErrorSegment } from "net-common-ui";
import { LicenseGrid } from "./Grid";
import { LicenseEdit } from "./Edit";

export const Routes: React.VFC = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <AuthRoute path={`${match.url}/:id(\\d+)`} exact component={LicenseEdit} />
            <AuthRoute path={match.url} exact component={LicenseGrid} />
            <Route>
                <ErrorSegment.NotFound />
            </Route>
        </Switch>
    );
};
