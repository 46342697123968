import { useState, useEffect } from "react";
import { Row, Col, Skeleton, Button, message, Modal } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useDataSource, Box, NetLayout, DateDisplay, NetSpin, NetTypography } from "net-common-ui";
import { deleteLicense, getLicenseById, License, saveLicenseLeaseTime } from "Models/License";
import { dsoLicenseLockByLicenseId, LicenseLock } from "Models/LicenseLock";
import LeaseTimeInput, { LeaseTimeInfo } from "Components/LeaseTimeInput";
import { AlertCircle } from "net-common-icons";
import { LockPane } from "./LockPane";
import { PingStatistic } from "./PingStatistic";

const { Paragraph } = NetTypography;

export const Edit: React.VFC = () => {
    const params = useParams<{ id: string }>();
    const licenseId = parseInt(params.id, 10);
    const history = useHistory();
    const [license, setLicense] = useState<License>(null);
    const [isInitial, setIsInitial] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [locks, locksDs] = useDataSource(dsoLicenseLockByLicenseId(licenseId), {
        configure: (b) => b.setQuery((b) => b.sort.set("id", "desc")),
        execute: true,
    });

    useEffect(() => {
        getLicenseById(params.id)
            .execute()
            .then((licenseResult) => {
                setLicense(licenseResult.item);
                setIsInitial(false);
            });
    }, [params.id]);

    const handleChange = (_lock: LicenseLock): void => {
        locksDs.execute({ force: true });
    };

    const handleDelete = () => {
        Modal.confirm({
            title: "Delete license?",
            // Temporarily hidden until net-common is packed and upgraded above 3.23.2
            icon: <AlertCircle />,
            content: "When license is deleted, lock status and history is also deleted - making the license unlocked.",
            okText: "Delete",
            okButtonProps: {
                type: "primary",
                loading: deleting,
            },
            cancelButtonProps: {
                disabled: deleting,
            },
            okType: "danger",
            async onOk() {
                setDeleting(true);
                const result = await deleteLicense(params.id).execute();
                if (result.success) {
                    setDeleting(false);
                    message.success("License successfully deleted.");
                    history.replace("/license"); // go back to grid (replace to forget this route)
                } else {
                    setDeleting(false);
                }
            },
        });
    };

    if (isInitial) {
        return (
            <Box
                size="expanded"
                childrenFlow="horizontal"
                className="mb-flex--align-items-center mb-flex--justify-content-center">
                <NetSpin size="large" />
            </Box>
        );
    }

    return (
        <Box size="expanded" childrenFlow="vertical">
            <NetLayout.Header />
            <NetLayout.SubHeader
                primaryAction={
                    <Button block danger onClick={handleDelete}>
                        Delete
                    </Button>
                }>
                <h4>License</h4>
            </NetLayout.SubHeader>
            <NetLayout.Content>
                <div className="mb-container">
                    <Row gutter={24}>
                        <Col span={8}>{license ? <LicenseDetails license={license} /> : <Skeleton active />}</Col>
                        <Col span={8}>
                            {locks.loading ? (
                                <NetSpin size="small" />
                            ) : (
                                <LockPane licenseId={licenseId} locks={locks.data} onChange={handleChange} />
                            )}
                        </Col>
                    </Row>
                </div>
            </NetLayout.Content>
        </Box>
    );
};

const LicenseDetails = ({ license }: { license: License }) => {
    const [leaseTime, setLeaseTime] = useState(license.leaseTime);
    const [updateLeaseVisible, setUpdateLeaseVisible] = useState(false);

    const updateLeaseTime = async () => {
        await saveLicenseLeaseTime(license.id, leaseTime).execute();
        message.success("Lease successfully saved.");
        license.leaseTime = leaseTime;
        setUpdateLeaseVisible(false);
    };
    const handleLeaseTimeChange = (value: number) => {
        setLeaseTime(value);
        setUpdateLeaseVisible(value !== license.leaseTime);
    };

    return (
        <>
            <Row gutter={24} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">
                        Lease time (minutes)&nbsp;
                        <LeaseTimeInfo />
                    </p>
                </Col>
                <Col span={24}>
                    <LeaseTimeInput value={leaseTime} onChange={handleLeaseTimeChange} />
                    {updateLeaseVisible && (
                        <Button className="mb-margin-left-xs" type="primary" onClick={updateLeaseTime}>
                            Update
                        </Button>
                    )}
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">License ID</p>
                    <Paragraph ellipsis={{ rows: 1, expandable: false }}>{license.licenseId}</Paragraph>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Licensee</p>
                    <Paragraph ellipsis={{ rows: 1, expandable: false }}>{license.licensee}</Paragraph>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Customer</p>
                    {license.customerName || license.customerEmail ? (
                        <>
                            <Paragraph ellipsis={{ rows: 1, expandable: false }}>{license.customerName}</Paragraph>
                            <Paragraph ellipsis={{ rows: 1, expandable: false }}>{license.customerEmail}</Paragraph>
                        </>
                    ) : (
                        <p>N/A</p>
                    )}
                </Col>
            </Row>
            <Row gutter={24} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Type</p>
                    <p>{license.type || "N/A"}</p>
                </Col>
            </Row>
            <Row gutter={24} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Platform</p>
                    <p>{license.platform || "N/A"}</p>
                </Col>
            </Row>
            {license.validFrom && (
                <Row gutter={24} className="mb-margin-bottom-md">
                    <Col span={24}>
                        <p className="mb-text-secondary-gray-color mb-margin-clear">Validity</p>
                        <p>
                            <DateDisplay value={license.validFrom} />
                            &nbsp;-&nbsp;
                            <DateDisplay value={license.validTo}>&lt;no expiry&gt;</DateDisplay>
                        </p>
                    </Col>
                </Row>
            )}

            <PingStatistic licenseId={license.licenseId} />
        </>
    );
};
