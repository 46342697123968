import { Tag } from "antd";
import { Link } from "react-router-dom";
import {
    useDataSource,
    NetTable,
    NetColumn,
    Box,
    NetLayout,
    NetPageHeader,
    NetPagination,
    applyGridState,
    useStateManager,
} from "net-common-ui";
import { dsoLicense, License } from "Models/License";
import { GridState } from "net-common-ui/dist/Data/GridState";
import { LicenseFilter, FilterFormSidebar } from "./FilterForm";
import { NewLicenseButton } from "../Create/NewLicenseButton";

export const Grid: React.VFC = () => {
    const [view, ds] = useDataSource(dsoLicense());
    const [state, sm] = useStateManager<GridState<LicenseFilter>>();

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb
                .set("licenseId", "contains", filter.licenseId)
                .set("licensee", "contains", filter.licensee)
                .set("platform", "contains", filter.platform)
                .set("customerName", "contains", filter.customerName)
                .set("customerEmail", "contains", filter.customerEmail)
                .set("licenseLock_isLocked", "eq", filter.isLocked),
        );
    });

    const handleFilterChange = (filter: LicenseFilter): void => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    return (
        <Box size="expanded" childrenFlow="horizontal">
            <FilterFormSidebar onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
            <Box size="expanded" childrenFlow="vertical">
                <NetLayout.Header>
                    <NetPageHeader title="Licenses" />
                </NetLayout.Header>
                <NetLayout.SubHeader primaryAction={<NewLicenseButton disabled={view.loading} />} />
                <Box size="expanded">
                    <Box className="mb-container mb-container--spacing mb-padding-bottom-clear" cover="height">
                        <NetTable<License>
                            dataSource={ds}
                            onSortChange={(sort) =>
                                sm.update((draft) => {
                                    draft.sort = sort;
                                })
                            }
                            className="mb-table--scrollable"
                            scroll={{ y: "100%", scrollToFirstRowOnChange: true }}>
                            <NetColumn field="licenseId" title="License ID" width="40%" sortable ellipsis />
                            <NetColumn field="licensee" title="Licensee" width="40%" sortable ellipsis />
                            <NetColumn field="platform" title="Platform" width="20%" sortable ellipsis />
                            <NetColumn
                                field="licenseLock.isLocked"
                                title="Status"
                                sortable
                                width={100}
                                render={(locked) =>
                                    locked ? <Tag color="error">Locked</Tag> : <Tag color="success">Unlocked</Tag>
                                }
                            />
                            <NetColumn
                                field="leaseTime"
                                title="Lease"
                                width={70}
                                render={(lease) => `${(lease / 60).toFixed(0)} min`}
                            />
                            <NetColumn
                                field="id"
                                key="action"
                                width={100}
                                render={(id) => (
                                    <Link className="ant-btn ant-btn-link ant-btn-sm" to={`/license/${id}`}>
                                        Manage
                                    </Link>
                                )}
                            />
                        </NetTable>
                    </Box>
                </Box>
                <NetLayout.Footer>
                    <Box className="mb-border-top-01 mb-padding-top-sm mb-padding-bottom-xl">
                        <NetPagination
                            dataSource={ds}
                            onChange={(page) =>
                                sm.update((draft) => {
                                    draft.page = page;
                                })
                            }
                        />
                    </Box>
                </NetLayout.Footer>
            </Box>
        </Box>
    );
};
