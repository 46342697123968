import { Alert, Col, Descriptions, Input, message, Row, Tag } from "antd";
import DhLicenseKeyComboBox from "Components/DhLicenseKeyComboBox";
import { isDhLicenseOnline } from "Models/Dh/DhLicense";
import { getDhLicenseFeatureByLicenseId } from "Models/Dh/DhLicenseFeature";
import { DhLicenseKey, dsoDhLicenseKey, getDhLicenseKeyByLicenseKey } from "Models/Dh/DhLicenseKey";
import { DhLicenseStatusValues } from "Models/Dh/DhLicenseStatus";
import { useDataSourceRef, useDebounce, NetTypography, Box } from "net-common-ui";
import React, { useState } from "react";

const { Paragraph } = NetTypography;

interface SearchExternalProps {
    value?: DhLicenseKey;
    onChange?: (model: DhLicenseKey) => void;
}

export const SearchExternal: React.VFC<SearchExternalProps> = ({ value, onChange }) => {
    const [licenseKey, setLicenseKey] = useState<DhLicenseKey>(value);
    const [licenseKeyDs] = useDataSourceRef<DhLicenseKey>(dsoDhLicenseKey());

    const handleChange = (licenseKey: DhLicenseKey): void => {
        if (licenseKey?.license?.id) {
            getDhLicenseFeatureByLicenseId(licenseKey.license.id)
                .execute()
                .then((response) => {
                    licenseKey.license.features = response.data;
                    setLicenseKey(licenseKey);
                    onChange && onChange(licenseKey);
                });
        }
    };

    return (
        <Row>
            <Col span={24}>
                <DhLicenseKeyComboBox dataSource={licenseKeyDs} onChange={handleChange} />

                {licenseKey && (
                    <Box className="mb-margin-top-xxs">
                        <LicenseInfo licenseKey={licenseKey} />
                    </Box>
                )}
            </Col>
        </Row>
    );
};

export const LicenseKeyInput: React.VFC<SearchExternalProps> = ({ value, onChange }) => {
    const [licenseKey, setLicenseKey] = useState<DhLicenseKey>(value || null);
    const [queryTerm, setQueryTerm] = useState<string>(""); // last key searched
    const [querying, setQuerying] = useState<boolean>(false); // is lookup in progress
    const [validate] = useDebounce(async (term: string) => {
        setQueryTerm(term);

        let match: DhLicenseKey = null;

        // dont query if theres nothing to query with
        if (term) {
            setQuerying(true);
            const result = await getDhLicenseKeyByLicenseKey(term).execute({
                errorHandler: false,
            });

            setQuerying(false);
            match = result.data?.length > 0 && result.data[0]; // multiple license records might get returned for the same license key, they're same in sdklock context
            if (result.success && match) {
                message.success("Successfully validated license key.");
            }

            if (match !== licenseKey) {
                if (match?.license?.id) {
                    getDhLicenseFeatureByLicenseId(match.license.id)
                        .execute()
                        .then((response) => {
                            match.license.features = response.data;

                            setLicenseKey(match);
                            onChange && onChange(match);
                        });
                } else {
                    setLicenseKey(null);
                    onChange && onChange(null);
                }
            }
        }
    }, 500);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        validate(e.currentTarget.value);
    };

    const handleSearch = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation(); // prevent parent form from submitting
        validate(key); // validate!
    };

    const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <Row>
            <Col span={24}>
                <Input.Search onSearch={handleSearch} onChange={handleChange} onKeyDown={handleKey} loading={querying} />
                {!querying && queryTerm && (
                    <div className="mb-margin-top-xs">
                        {licenseKey ? (
                            <LicenseInfo licenseKey={licenseKey} />
                        ) : (
                            <Alert message="Specified key was not found!" type="warning" />
                        )}
                    </div>
                )}
            </Col>
        </Row>
    );
};

const LicenseInfo = ({ licenseKey }: { licenseKey: DhLicenseKey }) => (
    <Descriptions title={null} layout="horizontal" column={1} size="small" bordered>
        <Descriptions.Item label="License ID">
            <Paragraph style={{ margin: 0 }} ellipsis={{ rows: 1, expandable: false }}>
                {licenseKey.keygenLicenseId}
            </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Licensee">
            <Paragraph style={{ margin: 0 }} ellipsis={{ rows: 1, expandable: false }}>
                {licenseKey.keygenLicensee}
            </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Options">
            {licenseKey.license.licenseStatusId === DhLicenseStatusValues.ACTIVE.id ? (
                <Tag color="success">Active</Tag>
            ) : (
                <Tag color="error">Expired</Tag>
            )}
            {isDhLicenseOnline(licenseKey.license) ? <Tag color="success">Online</Tag> : <Tag color="error">Not Online</Tag>}
        </Descriptions.Item>
    </Descriptions>
);
