import React from "react";
import { useLocation } from "react-router-dom";
import Config from "Config";
import { NetLayout, useAuth, Navigation } from "net-common-ui";
import { Lock } from "net-common-icons";

const { Item } = Navigation;

const MainLayout: React.FunctionComponent = ({ children }) => {
    const location = useLocation();
    const auth = useAuth();

    return (
        <NetLayout>
            <NetLayout.MainContent className="mb-layout-content">{children}</NetLayout.MainContent>
            <Navigation
                initialSelectedKey={location.pathname}
                // showAppSwitcher
                userInfo={{
                    displayName: auth?.user?.profile?.name || auth?.user?.profile?.username,
                    profileUrl: `${Config.authclient.baseUrl}?origin=${encodeURIComponent(window.location.href)}`,
                    pictureUrl: auth?.user?.profile?.picture,
                    signoutOnClick: auth.actions.signout,
                }}
                upperItems={
                    <>
                        <Item linkTo="/license" text="Licenses" icon={<Lock />} />
                    </>
                }
            />
        </NetLayout>
    );
};

export default MainLayout;
