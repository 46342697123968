import { Modal } from "antd";
import { AlertCircle } from "net-common-icons";

type Callback = (message: string, callback: (ok: boolean) => void) => void;

const callback: Callback = (message: string, callback: (ok: boolean) => void): void => {
    Modal.confirm({
        title: "Leave current page?",
        icon: <AlertCircle />,
        content: <p>{message}</p>,
        okText: "Ok",
        cancelText: "Cancel",
        onCancel: () => callback(false),
        onOk: () => callback(true),
    });
};

export default callback;
