import { UserManagerSettings } from "oidc-client";

export interface Configuration {
    userManager: UserManagerSettings;
    api: {
        sdklock: string;
        dwh: string;
        dha: string;
    };
    authclient: {
        baseUrl: string;
    };
}

// eslint-disable-next-line no-underscore-dangle
const CFG = window.__CFG__ as Configuration;
if (!CFG) throw new Error("Could not find global configuration. Make sure __CFG__ is loaded.");
export default CFG;
