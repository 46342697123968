import { Badge } from "antd";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { DhLicenseStatusId, DhLicenseStatusValues } from "Models/Dh/DhLicenseStatus";

interface DhLicenseStatusBadgeProps {
    licenseStatusId?: DhLicenseStatusId;
}

export const DhLicenseStatusBadge: React.VFC<DhLicenseStatusBadgeProps> = ({ licenseStatusId }) => {
    let status = "default";

    switch (licenseStatusId) {
        case DhLicenseStatusValues.DRAFT.id:
            status = "warning";
            break;
        case DhLicenseStatusValues.ACTIVE.id:
            status = "success";
            break;
        case DhLicenseStatusValues.EXPIRED.id:
            status = "error";
            break;
    }

    return <Badge status={status as PresetStatusColorType} />;
};
