import axios from "axios";
import Config from "Config";
import { authInterceptor, errorInterceptor, jsonPatchInterceptor } from "net-common-ui";

export const sdkLockApi = axios.create({ baseURL: Config.api.sdklock });
sdkLockApi.interceptors.request.use(authInterceptor);
sdkLockApi.interceptors.request.use(jsonPatchInterceptor);
sdkLockApi.interceptors.response.use((response) => response, errorInterceptor);

export const dwhApi = axios.create({ baseURL: Config.api.dwh });
dwhApi.interceptors.request.use(authInterceptor);
dwhApi.interceptors.request.use(jsonPatchInterceptor);
dwhApi.interceptors.response.use((response) => response, errorInterceptor);

export const dhaApi = axios.create({ baseURL: Config.api.dha });
dhaApi.interceptors.request.use(authInterceptor);
dhaApi.interceptors.request.use(jsonPatchInterceptor);
dhaApi.interceptors.response.use((response) => response, errorInterceptor);
