import { Form, Input, Button } from "antd";
import { BoolRadioButtonSet, FilterSidebar, withFilterSidebar } from "net-common-ui";
import { FilterFormProps } from "net-common-ui/dist/Components/FilterSidebar/withFilterSidebar";

export interface LicenseFilter {
    licenseId?: string;
    licensee?: string;
    platform?: string;
    customerName?: string;
    customerEmail?: string;
    isLocked?: boolean;
}

const FilterForm: React.VFC<FilterFormProps<LicenseFilter>> = (props) => {
    const { disabled } = props;

    return (
        <FilterSidebar.Form {...props}>
            <Form.Item name="licenseId" label="License Id">
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item name="licensee" label="Licensee">
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item name="platform" label="Platform">
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item name="customerName" label="Customer Name">
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item name="customerEmail" label="Customer Email">
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item name="isLocked" label="License locked">
                <BoolRadioButtonSet nullable disabled={disabled} />
            </Form.Item>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden disabled={disabled} />
        </FilterSidebar.Form>
    );
};

export const FilterFormSidebar = withFilterSidebar(FilterForm);
