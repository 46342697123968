import { Col, Row } from "antd";
import { DhLicenseKey } from "Models/Dh/DhLicenseKey";
import { Box, ComboBox, FilterBuilder, NetTypography } from "net-common-ui";
import { ComboBoxProps } from "net-common-ui/dist/Components/ComboBox/ComboBox";
import React from "react";
import { DhLicenseStatusBadge } from "Views/License/DhLicenseStatusBadge";

// Functions are in this scope (not inside component) because they are stateless, and all instances
// of DhLicenseKeyComboBox may reuse same function.

// Fixed way of displaying dropdown items
const itemTemplate = (model: DhLicenseKey) => {
    const partner = model?.license?.licenseRoot?.project?.partner;
    // const featureType = FeatureType.getById(model.featureTypeId);
    return (
        <Box childrenFlow="horizontal" className="mb-flex--align-items-center">
            <Box childrenFlow="vertical" size="expanded">
                <Row>
                    {partner && (
                        <Col span={10}>
                            <NetTypography.Paragraph ellipsis className="mb-margin-clear">
                                {partner.name}
                            </NetTypography.Paragraph>
                        </Col>
                    )}
                    <Col span={14}>
                        <NetTypography.Paragraph ellipsis className="mb-margin-clear">
                            {model.keygenLicenseId}
                        </NetTypography.Paragraph>
                    </Col>
                </Row>
                <Row>
                    {partner && (
                        <Col span={10}>
                            <NetTypography.Paragraph ellipsis className="mb-margin-clear">
                                {partner.email}
                            </NetTypography.Paragraph>
                        </Col>
                    )}
                    <Col span={14}>
                        <NetTypography.Paragraph ellipsis className="mb-margin-clear">
                            {model.keygenLicensee}
                        </NetTypography.Paragraph>
                    </Col>
                </Row>
            </Box>
            <Box size="compact" className="mb-padding-left-sm">
                <DhLicenseStatusBadge licenseStatusId={model.license.licenseStatusId} />
            </Box>
        </Box>
    );
};
// Fixed way of displaying selected items
const selectionTemplate = (model: DhLicenseKey) => {
    const partner = model?.license?.licenseRoot?.project?.partner;
    // return model.licenseId;
    return (
        <>
            <Box childrenFlow="horizontal" className="mb-flex--align-items-center">
                <Box size="expanded">
                    <NetTypography.Paragraph ellipsis className="mb-margin-clear">
                        {partner.name} - {model.keygenLicenseId}
                    </NetTypography.Paragraph>
                </Box>
                <Box size="compact" className="mb-padding-left-sm">
                    <DhLicenseStatusBadge licenseStatusId={model.license.licenseStatusId} />
                </Box>
            </Box>
        </>
    );
};

// Fixed way of filtering data for all DhLicenseKeyComboBox
const filter = (filter: FilterBuilder<DhLicenseKey>, term: string) => {
    filter
        .setLogic("or")
        .set("keygenLicenseId", "contains", term)
        .set("keygenLicensee", "contains", term)
        .set("license.licenseRoot.project.partner.email", "contains", term);
};

// Props we expose on this control are the same as ComboBox, but we remove a few with Omit<...>
type Props = Omit<ComboBoxProps<DhLicenseKey>, "children" | "itemTemplate" | "selectionTemplate" | "filter" | "filterField">;

// Simply wrap ComboBox, set some fixed fields, and leave rest as params as usual
export default React.forwardRef((props: Props, ref: React.Ref<any>) => (
    <ComboBox<DhLicenseKey>
        ref={ref}
        selectionTemplate={selectionTemplate}
        itemTemplate={itemTemplate}
        filter={filter}
        {...props}
    />
));
