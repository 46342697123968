import { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { Box, DateDisplay, NetSpin } from "net-common-ui";
import { DwhPingStatistic, getDwhPingStatisticByLicense } from "Models/Dwh/DwhPingStatistic";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc); // load utc plugin

interface PingStatisticProps {
    licenseId: string;
}

export const PingStatistic: React.VFC<PingStatisticProps> = ({ licenseId }) => {
    const [model, setModel] = useState<DwhPingStatistic>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const to = dayjs.utc();
        const from = dayjs.utc().add(-1, "month");

        getDwhPingStatisticByLicense(licenseId, from, to)
            .execute({ errorHandler: false })
            .then((result) => {
                if (result.success) {
                    setModel(result.item);
                }
                setLoading(false);
            });
    }, [licenseId]);

    if (loading) {
        return <NetSpin size="large" />;
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Box cover="width" className="mb-container-background-quaternary mb-padding-md">
                        <Row gutter={24}>
                            <Col span={24}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Total scans (last 30 days)</p>
                                <h3>{model.scans || 0}</h3>
                            </Col>
                        </Row>

                        <Row gutter={24} className="mb-padding-top-md">
                            <Col span={24}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Successful scan ratio</p>
                                <h4>{model.successfulScansRatio?.toFixed(2) || "N/A"}</h4>
                            </Col>
                        </Row>

                        <Row gutter={24} className="mb-padding-top-md">
                            <Col span={12}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Successful scans</p>
                                <h4>{model.successfulScans || 0}</h4>
                            </Col>
                            <Col span={12}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Uncertain scans</p>
                                <h4>{model.uncertainScans || 0}</h4>
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>

            <Row gutter={24} className="mb-padding-top-md">
                <Col span={24}>
                    <Box cover="width" className="mb-container-background-quaternary mb-padding-md">
                        <Row gutter={24}>
                            <Col span={12}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Pings</p>
                                <h4>{model.pings || 0}</h4>
                            </Col>
                            <Col span={12}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Last ping</p>
                                <h4>
                                    <DateDisplay value={model.lastPingDate}>N/A</DateDisplay>
                                </h4>
                            </Col>
                        </Row>
                        <Row gutter={24} className="mb-padding-top-md">
                            <Col span={12}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Users</p>
                                <h4>{model.users || 0}</h4>
                            </Col>
                            <Col span={12}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Locations</p>
                                <h4>{model.locations || 0}</h4>
                            </Col>
                        </Row>
                        <Row gutter={24} className="mb-padding-top-md">
                            <Col span={24}>
                                <p className="mb-text-secondary-gray-color mb-margin-clear">Applications</p>
                                <h4>{model.multipleApps || 0}</h4>
                            </Col>
                        </Row>
                        {model.dashboardLink && (
                            <Row gutter={24} className="mb-padding-top-md">
                                <Col span={24}>
                                    <Box childrenFlow="horizontal" cover="width" className="mb-flex--justify-content-center">
                                        <Button type="link" href={model.dashboardLink} target="blank">
                                            View dashboard
                                        </Button>
                                    </Box>
                                </Col>
                            </Row>
                        )}
                    </Box>
                </Col>
            </Row>
        </>
    );
};
