import { sdkLockApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface License {
    id?: number;
    identifier?: string;
    leaseTime?: number;
    licenseId?: string;
    licensee?: string;
    type?: string;
    platform?: string;
    customerName?: string;
    customerEmail?: string;
    isTimeRestricted?: boolean;
    createdOn?: Dayjs;
    activatedOn?: Dayjs;
    validFrom?: Dayjs;
    validTo?: Dayjs;
}

export function getLicenseById(id: UrlParam): ApiCall<License> {
    return ApiCall.get(sdkLockApi, `license/${id}`);
}

export function getLicenseInfoById(id: UrlParam): ApiCall<License> {
    return ApiCall.get(sdkLockApi, `license/${id};info`);
}

export function getLicenseByLicenseId(licenseId: UrlParam, licensee: UrlParam): ApiCall<License> {
    return ApiCall.get(sdkLockApi, `license/${licenseId}`, { licensee });
}

export function saveLicense(model: License): ApiCall<License> {
    return ApiCall.put(sdkLockApi, `license`, model);
}

export function saveLicenseLeaseTime(id: UrlParam, leaseTime: number): ApiCall<License> {
    return ApiCall.patch(sdkLockApi, `license/${id}/leaseTime`, leaseTime);
}

export function deleteLicense(id: UrlParam): ApiCall<License> {
    return ApiCall.delete(sdkLockApi, `license/${id}`);
}

export function dsoLicense(): DataSourceOptions<License> {
    return {
        api: sdkLockApi,
        url: "license",
    };
}
