import ReactDOM from "react-dom";
import {
    OidcAuthManager as AuthManager,
    AuthContextProvider,
    ErrorPage,
    NetConfigProvider,
    forceVisibleScrollbars,
} from "net-common-ui";
import Config, { Configuration } from "Config";
import App from "./Views/App";
import * as serviceWorker from "./serviceWorker";
import "net-common-icons/dist/Style/style.less";
import "Common/src/Style/style.less";

declare global {
    interface Window {
        __CFG__: Configuration;
        reset: () => void;
        dataLayer: any;
    }
}

const cfg = {
    oidcConfig: Config.userManager,
    initializeEndpoint: `${Config.api.sdklock}/system/initialize`,
};

AuthManager.configure(cfg)
    .init()
    .then((result) => {
        if (result.type === "user" && result.user) {
            // antd ima issue sa strict modom u buttonu: https://github.com/ant-design/ant-design/issues/22493
            // ReactDOM.render(<React.StrictMode><AuthContextProvider manager={AuthManager}><App /></AuthContextProvider></React.StrictMode>, document.getElementById('root'));
            ReactDOM.render(
                <AuthContextProvider authDialog silentSignin>
                    <NetConfigProvider>
                        <App />
                    </NetConfigProvider>
                </AuthContextProvider>,
                document.getElementById("root"),
            );
        } else if (result.type === "error") {
            ReactDOM.render(<ErrorPage error={result.error} user={result.user} />, document.getElementById("root"));
        }
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.reset = function reset() {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = "";
};

// This fix ensures scrollbars are visible on macos.
forceVisibleScrollbars();
