import { dwhApi } from "Api";
import { ApiCall } from "net-common-ui";
import { Dayjs } from "dayjs";

// model used when querying DWH endpoint for licenses
export interface DwhPingStatistic {
    licenseId?: string;
    from?: Dayjs;
    to?: Dayjs;

    scans?: number;
    successfulScans?: number;
    successfulScansRatio?: number;
    uncertainScans?: number;
    pings?: number;
    users?: number;
    locations?: number;
    multipleApps?: number;
    lastPingDate?: Dayjs;
    dashboardLink?: string;
}

export function getDwhPingStatisticByLicense(licenseId: string, from: Dayjs, to: Dayjs): ApiCall<DwhPingStatistic> {
    return ApiCall.get(dwhApi, "ping/statistic", {
        licenseId,
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
    });
}
