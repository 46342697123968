import React from "react";
import { InputNumber, Popover, Space } from "antd";
import { AlertCircle } from "net-common-icons";

interface Props {
    value: number;
    onChange: (value: number) => void;
}

// ove 3 bi trebale bit povucene sa backenda
export const DEFAULT_LEASE = 60 * 5;
// min lease time in seconds
export const MIN_LEASE = 60; // 1 min
// max lease time in seconds
export const MAX_LEASE = 60 * 60 * 24 * 30 * 12; // 360 days

// value is in seconds, while we display it in minutes
export default React.forwardRef(({ value, onChange }: Props, ref: React.Ref<any>) => {
    return (
        <InputNumber
            ref={ref}
            min={MIN_LEASE / 60}
            max={MAX_LEASE / 60}
            type="number"
            value={value / 60}
            onChange={(value: number) => onChange(value * 60)}
            style={{ width: "calc(50% - 32px)" }}
        />
    );
});

export const LeaseTimeInfo: React.VFC = () => {
    return (
        <Popover
            placement="right"
            title="Lease time"
            content={
                <Space direction="vertical" style={{ maxWidth: 300 }}>
                    <div>
                        Lease time will be converted to seconds and included in status check replies. Value is currently limited
                        to [{formatTimeLimit(MIN_LEASE)}, {formatTimeLimit(MAX_LEASE)}] range.
                    </div>
                    <div>
                        If you put high value, SDK will need to query status less often, but lock action will be slightly delayed.
                        If value is set low, SDK will need to query often, but lock action will be propagated sooner.
                    </div>
                </Space>
            }>
            <AlertCircle className="mb-margin-left-xs" style={{ cursor: "help" }} />
            &nbsp;
        </Popover>
    );
};

function formatTimeLimit(value: number): string {
    if (value < 60) {
        return `${value} sec`;
    }
    if (value < 60 * 60) {
        return `${(value / 60).toFixed(0)} min`;
    }
    if (value < 60 * 60 * 24) {
        return `${(value / 60 / 60).toFixed(0)} hr`;
    }

    return `${(value / 60 / 60 / 24).toFixed(0)} d`;
}
