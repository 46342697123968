import { useState } from "react";
import { Button, Checkbox, Divider, Form, Input, message, Radio, Tooltip } from "antd";
import LeaseTimeInput, { DEFAULT_LEASE, LeaseTimeInfo } from "Components/LeaseTimeInput";
import { toLicenseModel } from "Models/Dh/DhLicense";
import { DhLicenseKey } from "Models/Dh/DhLicenseKey";
import { License, saveLicense } from "Models/License";
import { LicenseLock, saveLicenseLock } from "Models/LicenseLock";
import { NetModal } from "net-common-ui";
import { useHistory } from "react-router-dom";
import { LicenseKeyInput, SearchExternal } from "./ExternalLookup";

type InputMode = "query" | "manual" | "key";

interface CreateModel {
    inputMode: InputMode;
    // common
    leaseTime?: number;
    // manual entry
    licenseId?: string;
    licensee?: string;
    // dh lookup
    externalLicense: DhLicenseKey;

    // lock params
    isLocked: boolean;
    reason: string;
}

interface NewLicenseButtonProps {
    disabled: boolean;
}

export const NewLicenseButton: React.VFC<NewLicenseButtonProps> = ({ disabled }) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleCancel = (): void => {
        setOpen(false);
    };
    const handleSave = async (model: CreateModel): Promise<void> => {
        let license: License;
        switch (model.inputMode) {
            case "manual":
                license = {
                    licenseId: model.licenseId,
                };
                break;
            case "query":
            case "key":
                license = toLicenseModel(model.externalLicense);
                break;
        }
        license.leaseTime = model.leaseTime;

        if (!license.licenseId) {
            message.error("Both Licensee and License ID are required!");
        } else {
            setSaving(true);
            const result = await saveLicense(license).execute();
            if (result.success) {
                let licenseLocked = false;
                if (model.isLocked) {
                    const licenseLock: LicenseLock = {
                        licenseId: result.item.id,
                        isLocked: model.isLocked,
                        reason: model.reason,
                    };
                    const lockResult = await saveLicenseLock(licenseLock).execute();
                    licenseLocked = lockResult.success;
                }

                setOpen(false);
                setSaving(false);

                message.success(licenseLocked ? "License successfully saved and locked." : "License successfully saved.");
                history.push(`/license/${result.item.id}`);
            } else {
                setSaving(false);
            }
        }
    };

    const handleChange = (diff: Partial<CreateModel>, model: CreateModel): void => {
        // reset license input on type change
        if (diff.inputMode) {
            form.setFieldsValue({
                externalLicense: null,
                licensee: null,
                licenseId: null,
            });
        }
    };

    return (
        <>
            <Button type="primary" block onClick={handleOpen} disabled={disabled}>
                Add license
            </Button>
            <NetModal
                title="New license"
                open={open}
                onOk={() => form.submit()}
                okButtonProps={{ loading: saving }}
                onCancel={handleCancel}
                cancelButtonProps={{ disabled: saving }}
                destroyOnClose
                okText="Save">
                {/* leaseTime: 300 fixati kasnije -> ne radi dobro ako je u input kontroli podešena defaulta vrijenost, kaže svejedno da je field required bez obzira što ima setiran value */}
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ inputMode: "key", isLocked: true, leaseTime: DEFAULT_LEASE }}
                    preserve={false}
                    onFinish={handleSave}
                    onValuesChange={handleChange}>
                    <Form.Item name="inputMode">
                        <Radio.Group>
                            <Tooltip title="Search existing licenses in Developer Hub. Recommended.">
                                <Radio value="query">Query Developer Hub</Radio>
                            </Tooltip>
                            <Tooltip title="Enter license key. Validity is checked against Developer Hub records. Recommended.">
                                <Radio value="key">Enter key</Radio>
                            </Tooltip>
                            <Tooltip title="Enter license data manually. Useful for testing purposes when license does not exist.">
                                <Radio value="manual">Manual entry</Radio>
                            </Tooltip>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item noStyle dependencies={["inputMode"]}>
                        {() => {
                            const inputMode = form.getFieldValue("inputMode");

                            switch (inputMode) {
                                case "query":
                                    return (
                                        <Form.Item
                                            name="externalLicense"
                                            label="Search license"
                                            rules={[{ required: true, message: "Valid license key is required." }]}>
                                            <SearchExternal />
                                        </Form.Item>
                                    );
                                case "key":
                                    return (
                                        <Form.Item
                                            name="externalLicense"
                                            label="Enter license key"
                                            rules={[{ required: true, message: "Valid license key is required." }]}>
                                            <LicenseKeyInput />
                                        </Form.Item>
                                    );
                                case "manual":
                                    return (
                                        <>
                                            <Form.Item name="licenseId" label="License Id" rules={[{ required: true }]}>
                                                <Input />
                                            </Form.Item>
                                        </>
                                    );
                                default:
                                    return <></>;
                            }
                        }}
                    </Form.Item>

                    <Form.Item label="Lease time (min)">
                        <Form.Item noStyle name="leaseTime" rules={[{ required: true }]}>
                            <LeaseTimeInput value={null} onChange={null} />
                        </Form.Item>
                        <LeaseTimeInfo />
                    </Form.Item>

                    <Divider />

                    <Form.Item>
                        <Form.Item noStyle name="isLocked" valuePropName="checked">
                            <Checkbox>Lock immediately</Checkbox>
                        </Form.Item>

                        <Form.Item dependencies={["isLocked"]} className="mb-margin-top-xs">
                            {(form) => (
                                <Form.Item name="reason">
                                    <Input.TextArea placeholder="Lock reason" disabled={!form.getFieldValue("isLocked")} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Form.Item>

                    {/* This makes enter submit the form - button is not visible! */}
                    <Button htmlType="submit" hidden />
                </Form>
            </NetModal>
        </>
    );
};
