import { ApiCall } from "net-common-ui";
import { dhaApi } from "Api";
import { Dayjs } from "dayjs";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { DhLicense } from "./DhLicense";

export interface DhLicenseKey {
    id?: number;
    identifier?: string;
    keygenLicenseId?: string;
    keygenLicensee?: string;
    value?: string;
    createdOn?: Dayjs;
    license?: DhLicense;
}

export function getDhLicenseKeyByLicenseKey(licenseKey: string): ApiCall<DhLicenseKey> {
    return ApiCall.get(dhaApi, `license-key/value(${encodeURIComponent(licenseKey)})`);
}

export function dsoDhLicenseKey(): DataSourceOptions<DhLicenseKey> {
    return {
        api: dhaApi,
        url: "license-key",
    };
}
