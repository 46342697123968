import { dhaApi } from "Api";
import { ApiCall } from "net-common-ui";
import { DhFeature } from "./DhFeature";

export interface DhLicenseFeature {
    name?: string;
    feature?: DhFeature;
}

export function getDhLicenseFeatureByLicenseId(licenseId: number): ApiCall<DhLicenseFeature> {
    return ApiCall.get(dhaApi, `license/${licenseId}/license-feature`);
}
