import { useState } from "react";
import { Button, Row, Col, Input, message, Badge } from "antd";
import { LicenseLock, saveLicenseLock, unlockLicenseLock } from "Models/LicenseLock";
import { Box, DateDisplay } from "net-common-ui";

interface LockPaneProps {
    licenseId: number;
    locks: LicenseLock[];
    onChange: (model: LicenseLock) => void;
}
export const LockPane: React.VFC<LockPaneProps> = ({ licenseId, locks, onChange }) => {
    const [historyVisible, setHistoryVisible] = useState(false);
    let current: LicenseLock;
    let history: LicenseLock[];

    // make sure to sort it descending (id is good enough)
    locks.sort((a, b) => b.id - a.id);

    if (locks.length && locks[0].isLocked) {
        current = locks[0];
        history = locks.slice(1);
    } else {
        current = { licenseId };
        history = locks || [];
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    {current.isLocked ? (
                        <CurrentLocked lock={current} onChange={onChange} />
                    ) : (
                        <CurrentUnlocked lock={current} onChange={onChange} />
                    )}
                </Col>
            </Row>

            {history.length > 0 && (
                <Row gutter={16} className="mb-margin-top-xs">
                    <Col span={24} className="mb-text-center">
                        {!historyVisible && (
                            <Button type="link" onClick={() => setHistoryVisible(true)}>
                                Show history
                            </Button>
                        )}
                        {historyVisible && (
                            <Button type="link" onClick={() => setHistoryVisible(false)}>
                                Hide history
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
            {historyVisible && (
                <Row gutter={16} className="mb-margin-top-md">
                    <Col span={24}>
                        {history.map((l) => (
                            <PreviousLock key={l.id} lock={l} />
                        ))}
                    </Col>
                </Row>
            )}
        </>
    );
};

const CurrentLocked = ({ lock, onChange }: { lock: LicenseLock; onChange: (model: LicenseLock) => void }) => {
    const [locking, setLocking] = useState<boolean>(false);

    const handleUnlock = async () => {
        setLocking(true);
        const model: LicenseLock = { ...lock, ...{ leaseTime: null, isLocked: false } };
        const result = await unlockLicenseLock(lock.id).execute();
        if (result.success) {
            message.success("License successfully unlocked.");
            onChange(model);
            setLocking(false);
        } else {
            setLocking(false);
        }
    };

    return (
        <div className="mb-container mb-container-background-primary mb-border-01 mb-shadow-dark-xxs mb-padding-xl">
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <Box childrenFlow="horizontal" className="mb-border-bottom-01">
                        <Box size="expanded">
                            <h4 className="mb-padding-bottom-sm">Locked</h4>
                        </Box>
                        <Box size="compact">
                            <Badge status="error" />
                        </Box>
                    </Box>
                </Col>
            </Row>
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Locked on</p>
                    <p className="mb-margin-clear">
                        <DateDisplay value={lock.lockedOn} />
                    </p>
                </Col>
            </Row>
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Locked by</p>
                    <p className="mb-margin-clear">{lock.lockedBy.name}</p>
                </Col>
            </Row>
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Lock reason</p>
                </Col>
                <Col span={24}>
                    <Input.TextArea disabled rows={4} value={lock.reason} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12} offset={12}>
                    <Button type="primary" block onClick={handleUnlock} loading={locking}>
                        Unlock
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const CurrentUnlocked = ({ lock, onChange }: { lock: LicenseLock; onChange: (model: LicenseLock) => void }) => {
    const [reason, setReason] = useState(lock.reason);
    const [locking, setLocking] = useState<boolean>(false);

    const handleLock = async () => {
        setLocking(true);
        const model: LicenseLock = { ...lock, ...{ reason, isLocked: true } };
        const result = await saveLicenseLock(model).execute();
        if (result.success) {
            message.success("License successfully locked.");
            onChange(model);
            setLocking(false);
        } else {
            setLocking(false);
        }
    };

    return (
        <div className="mb-container mb-container-background-primary mb-border-01 mb-shadow-dark-xxs mb-padding-xl">
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <Box childrenFlow="horizontal" className="mb-border-bottom-01">
                        <Box size="expanded">
                            <h4 className="mb-padding-bottom-sm">Unlocked</h4>
                        </Box>
                        <Box size="compact">
                            <Badge status="success" />
                        </Box>
                    </Box>
                </Col>
            </Row>
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Lock reason</p>
                </Col>
                <Col span={24}>
                    <Input.TextArea rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12} offset={12}>
                    <Button type="primary" block onClick={handleLock} loading={locking}>
                        Lock
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const PreviousLock = ({ lock }: { lock: LicenseLock }) => {
    return (
        <div className="mb-container mb-container-background-quinary mb-padding-xl mb-margin-bottom-md">
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <h4 className="mb-padding-bottom-sm mb-border-bottom-01">Previously locked</h4>
                </Col>
            </Row>
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Locked</p>
                    <p className="mb-margin-clear">
                        <DateDisplay value={lock.lockedOn} /> by {lock.lockedBy.name}
                    </p>
                </Col>
            </Row>
            <Row gutter={16} className="mb-margin-bottom-md">
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Unlocked</p>
                    <p className="mb-margin-clear">
                        <DateDisplay value={lock.unlockedOn} /> by {lock.unlockedBy.name}
                    </p>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <p className="mb-text-secondary-gray-color mb-margin-clear">Lock reason</p>
                    <p className="mb-margin-clear">{lock.reason || "N/A"}</p>
                </Col>
            </Row>
        </div>
    );
};
