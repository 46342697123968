import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export type DhLicenseStatusId = "Draft" | "Active" | "Expired";

export interface DhLicenseStatus {
    id?: DhLicenseStatusId;
    name?: string;
}

export const DRAFT: DhLicenseStatus = { id: "Draft", name: "Draft" };
export const ACTIVE: DhLicenseStatus = { id: "Active", name: "Active" };
export const EXPIRED: DhLicenseStatus = { id: "Expired", name: "Expired" };

const ALL = [DRAFT, ACTIVE, EXPIRED];

export function dsoDhLicenseStatus(): DataSourceOptions<DhLicenseStatus> {
    return {
        data: ALL,
    };
}

export function getDhLicenseStatusById(id: DhLicenseStatusId): DhLicenseStatus {
    return ALL.find((x) => x.id === id);
}

export const DhLicenseStatusValues = {
    DRAFT,
    ACTIVE,
    EXPIRED,
} as const;
