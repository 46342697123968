import { sdkLockApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { ConsumerActor } from "net-common-ui/dist/Auth/ConsumerActor";

export interface LicenseLock {
    id?: number;
    licenseId?: number;
    reason?: string;
    isLocked?: boolean;
    lockedOn?: Dayjs;
    lockedBy?: ConsumerActor;
    unlockedOn?: Dayjs;
    unlockedBy?: ConsumerActor;
}

export function getLicenseLockById(id: UrlParam): ApiCall<LicenseLock> {
    return ApiCall.get(sdkLockApi, `license-lock/${id}`);
}

export function saveLicenseLock(model: LicenseLock): ApiCall<LicenseLock> {
    return ApiCall.put(sdkLockApi, "license-lock", model);
}

export function unlockLicenseLock(id: UrlParam): ApiCall<LicenseLock> {
    return ApiCall.patch(sdkLockApi, `license-lock/${id}/isLocked`, false); // body is isLocked value: false
}

export function dsoLicenseLockByLicenseId(licenseId: UrlParam): DataSourceOptions<LicenseLock> {
    return {
        api: sdkLockApi,
        url: `license/${licenseId}/license-lock`,
    };
}
