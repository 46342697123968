import { License } from "Models/License";
import { DhLicenseFeature } from "./DhLicenseFeature";
import { DhLicenseKey } from "./DhLicenseKey";
import { DhLicenseRoot } from "./DhLicenseRoot";
import { DhLicenseStatusId } from "./DhLicenseStatus";

export function toLicenseModel(dh: DhLicenseKey): License {
    return {
        identifier: dh.identifier,
        licenseId: dh.keygenLicenseId,
        licensee: dh.keygenLicensee,
        type: dh.license?.licenseRoot?.licenseType?.code,
        platform: dh.license?.licenseRoot?.sdk?.platform?.displayName,
        customerName: dh.license?.licenseRoot?.project?.partner?.name,
        customerEmail: dh.license?.licenseRoot?.project?.partner?.email,
    };
}

export interface DhLicense {
    id?: number;
    licenseRoot?: DhLicenseRoot;
    licenseStatusId?: DhLicenseStatusId;
    features?: DhLicenseFeature[];
}

export function isDhLicenseOnline(dhLicense: DhLicense): boolean {
    return dhLicense?.features && dhLicense.features.findIndex((f) => f.feature.code.toLowerCase() === "online") >= 0;
}
